/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --brand1: #940ee2d6; /* Gold (used for brand1 like hover effects) */
  --error: #ff1e31; /* Keep the error color consistent */
  --primary: #2e2e30; /* Dark gray background (same as character card background) */
  --secondary: #38383a; /* Slightly lighter gray for secondary backgrounds */
  --tertiary: #444444; /* Dark border color */
  --neutral: #ffffff; /* White for text and light backgrounds */
  --neutral2: #f4f4f4;
  --neutral3: #cccccc; /* Light gray for input backgrounds and labels */
  --background: #12171e; /* Very dark background, used for overall page or containers */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

header {
  background-color: var(--background);
}

.wrapper {
  display: flex;
}

.user-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 100%;
  border: 1px solid var(--neutral2);
}

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 80px;
  background-color: var(--background);
  padding: 0.4rem 0.8rem;
  transition: all 0.5s ease;
  z-index: 1000;
}

.sidebar.active ~ .main-content {
  left: 250px;
  width: calc(100% - 250px);
}

.sidebar.active {
  position: sticky;
  width: 250px;
}

.sidebar #btn {
  position: absolute;
  color: var(--neutral);
  top: 0.4rem;
  left: 50%;
  font-size: 1.2rem;
  line-height: 50px;
  transform: translateX(-50%);
  cursor: pointer;
}

.sidebar.active #btn {
  left: 90%;
}

.sidebar .top .logo {
  color: var(--neutral);
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  pointer-events: none;
  opacity: 0;
}

.sidebar.active .top .logo {
  opacity: 1;
}

.top .logo i {
  font-size: 2rem;
  margin-right: 5px;
}

.user {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.user p {
  color: var(--neutral);
  opacity: 1;
  margin-left: 1rem;
  display: none;
}

.sidebar.active .user p {
  display: block;
}

.sidebar p {
  opacity: 0;
}

.sidebar.active p {
  opacity: 1;
}

.sidebar ul li {
  position: relative;
  list-style-type: none;
  height: 50px;
  width: 90%;
  margin: 0.8rem auto;
  line-height: 50px;
}

.sidebar ul li a {
  color: var(--neutral);
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 0.8rem;
}

.sidebar ul li a:hover {
  background-color: var(--neutral);
  color: var(--background);
}

.sidebar ul li a i {
  min-width: 50px;
  text-align: center;
  height: 50px;
  border-radius: 12px;
  line-height: 50px;
}

.sidebar .nav-item {
  opacity: 0;
  display: none;
}

.sidebar.active .nav-item {
  opacity: 1;
  display: block;
}

.sidebar ul li .tooltip {
  background-color: var(--neutral);
  position: absolute;
  left: 125px;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  padding: 0.4rem 1.2rem;
  line-height: 1.8rem;
  z-index: 20;
  opacity: 0;
}

.sidebar ul li:hover .tooltip {
  opacity: 1;
}

.sidebar.active ul li:hover .tooltip {
  display: none;
}

.sidebar-text {
  font-size: 0.8rem;
}













.main-content {
  position: relative;
  background-color: var(--neutral);
  min-height: 100vh;
  width: 100%;
  top: 0;
  transition: all 0.5s ease;
  padding: 1rem;
}

.bold {
  font-weight: 600;
}

.centre {
  display: flex;
  height: 100%;
}









/* Login Form */
.login form * {
  display: block;
  width: 100%;
}

.login {
  max-width: 350px;
  margin: auto;
  padding: 30px;
  border: 1px solid var(--neutral3);
  border-radius: 5px;
  background-color: var(--neutral);
}

.login label {
  margin-top: 20px;
  margin-bottom: 5px;
}

.login p {
  font-size: 0.7rem;
  text-align: center;
}

input {
  border: none;
  border-bottom: 1px solid var(--brand1);
  height: 36px;
  margin-top: 20px;
  padding-left: 10px;
}

input:focus {
  outline: none;
  background-color: var(--neutral2);
}

.login button {
  margin: 35px 0;
  border: none;
  border-radius: 15px;
  padding: 10px;
  color: var(--neutral);
  background-color: var(--primary);
  transition: 0.3s ease;
}

.login button:hover {
  background-color: var(--brand1);
  font-weight: 900;
}

.login h3 {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.login a {
  text-decoration: none;
  font-weight: 900;
  color: #000;
}

div.error {
  padding: 10px;
  background: var(--neutral2);
  border-left: 5px solid var(--error);
  margin: 20px 0;
}











.character-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: var(--neutral);
  border: 2px solid var(--neutral3);
  width: 100%;
  max-width: 350px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  color: var(--primary);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.card h2 {
  display: inline-block;
  font-size: 24px;
  color: var(--primary);
  margin-bottom: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
  margin-right: auto;
}

.material-symbols-outlined {
  float: right;
  cursor: pointer;
  color: var(--primary);
}

.divider {
  margin: -10px;
}

.divider svg {
  width: 100%;
  height: auto;
}

.card .attribute {
  margin: 10px 0;
  padding: 10px;
  background-color: var(--neutral2);
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attribute-left,
.attribute-right {
  display: flex;
  justify-content: space-between;
  width: 45%;
}

.card .attribute span {
  font-weight: bold;
}

.card .attribute span.label {
  font-weight: normal;
  color: var(--primary);
}

.dropdown-button {
  background-color: transparent;
  color: var(--brand1);
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease;
}

.dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-section {
  transition: max-height 1s ease;
}








.form label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form input {
  display: block;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  background-color: var(--neutral2);
  color: var(--tertiary);
  border: none;
}

.form input:focus {
  border-color: var(--brand1);
  outline: none;
}

.form input.error {
  border: 1px solid var(--error);
}

.form .error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin-top: 10px;
}

.form button {
  background: var(--primary);
  color: var(--neutral);
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 15px;
}

.form button:hover {
  background-color: var(--brand1);
  font-weight: 900;
}





.character-info {
  width: 100%;
  max-width: 500px;
}

.character-info * {
  display: block;
}

.character-info button,
.manual-roll button,
.add-counter button,
.counter button {
  background: var(--tertiary);
  color: var(--neutral);
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 10px;
}

.character-info button:hover,
.manual-roll button:hover,
.add-counter button:hover,
.counter button:hover {
  background-color: var(--brand1);
  font-weight: 900;
}

.manual-roll {
  margin-top: 20px;
  gap: 10px;
  align-items: center;
}

.manual-roll button {
  padding: 10px 20px;
}

.character-info select {
  margin-top: 20px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--tertiary);
  background-color: var(--secondary);
  color: var(--neutral);
  width: 100%;
}

.counter div{
  margin-top: 20px;
}

.counter button {
  margin-right: 10px;
  padding: 5px 15px;
}

.add-counter {
  margin-top: 30px;
}

.update-character {
  margin-top: 20px;
}

.update-character input {
  margin-top: 5px;
}

.update-character label {
  margin-top: 15px;
}

.flex, .flex *{
  display: flex;
  flex-wrap: wrap;
}

/*

.campaign-details table {
  width: 100%;
  border-collapse: collapse;
}

.campaign-details th,
.campaign-details td {
  padding: 5px;
  border-bottom: 1px solid var(--tertiary);
}

.campaign-details th {
  text-align: left;
  color: var(--brand1);
}

.campaign-details td {
  color: var(--neutral);
}

.campaign-details button {
  background: var(--tertiary);
  color: var(--brand1);
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.campaign-details button:hover {
  background-color: var(--brand1);
  color: var(--tertiary);
}

.character-details {
  max-width: 800px;
}

@media (max-width: 768px) {
  .character-details {
    padding: 20px;
  }

  .manual-roll {
    flex-direction: column;
  }

  .manual-roll input {
    width: 100%;
  }

  .manual-roll button {
    width: 100%;
  }

  .update-character input {
    width: 100%;
  }
} */